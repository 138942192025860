import { ArrowLeftOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Badge,
  Button,
  Col,
  Table,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { Link, useParams } from "react-router-dom";
import useApi from "../api-hooks/useApi";
import useOrderDetails from "../api-hooks/useOrderDetails";
import { queryKeys } from "../constants/queryKeys";
import { currencyFormat } from "../services/utils";
import { getTaxCode, getTaxes, getTaxReg } from "../helper/localStorage";
import { useSelector } from "react-redux";
import posPlaceholder from "../assets/pos-order-logo.jpeg";
import { useState } from "react";
import Flex from "../components/Flex/Flex";

function CanceledOrderInvoice({ orderDetail }) {
  const InvoiceCopyStyles = css`
    width: 30rem;
    max-width: 100%;
    margin: auto;
    position: relative;
    padding: 3rem 1.5rem 2rem;
    /* border: 1px solid #777;› */
    background-color: #fff;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    font-size: 12px;
    text-transform: capitalize;
    line-height: 1.4;
    color: rgba(0, 0, 0, 0.95);

    .float {
      position: absolute;
      top: 2rem;
      right: 2rem;
      color: #444;
    }

    .alin-right {
      text-align: end;
    }
    .title {
      font-weight: 600;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3px;
      margin-bottom: 0.5rem;

      img {
        max-width: 7rem;
      }
    }
    .basic-info {
      padding-bottom: 1rem;
      border-bottom: 1px solid #aaa;
    }
  `;

  const [logoImgError, setLogoImgError] = useState(false);
  const cols = [
    {
      title: "الكمية",
      dataIndex: "quantity",
      key: "qty",
      align: "center",
    },
    {
      title: "المنتج",
      dataIndex: "productName",
      key: "name",
      align: "center",
    },
    {
      title: "السعر",
      dataIndex: "price",
      key: "price",
      align: "center",
    },
    {
      title: "الاجمالي",
      dataIndex: "total",
      key: "total",
      align: "center",
      colSpan: 2,
      // onHeaderCell: () => ({
      //   colSpan: 0,
      // }),
      render: (_, record) => {
        return {
          children: <span>{record.total}</span>,
          props: {
            colSpan: 2,
          },
        };
      },
    },
  ];
  const taxesArray = getTaxes();
  const currentUser = useSelector((s) => s?.auth?.currentUser);
  // const api = useApi();
  // const httpFunc = async (fd) => {
  //   const res = await api.post("changeItemsStatusForCancel", fd);
  //   return res;
  // };

  // const { id } = useParams();
  // const client = useQueryClient();

  // const { mutate: onChangeItemStatus, isLoading: onChangeItemStatusLod } =
  //   useMutation(httpFunc, {
  //     onSuccess: (res) => {
  //       console.log("CanceledOrderDetails  res:", res);
  //       if (res?.code === 200) {
  //         message.success(res?.message);
  //       }
  //       client.invalidateQueries([queryKeys.orderDetails, id]);
  //     },
  //   });

  // const { orderDetail, orderDetailLod } = useOrderDetails(id);
  // console.log("CanceledOrderDetails  orderDetail:", orderDetail);

  const calcTotalAfterTaxes = (taxes, order) => {
    // const taxes = getTaxes();
    // console.log({ taxes, order });

    let totalAfterTaxes = 0;
    order?.order_items.forEach((item) => {
      if (item.taxable == 1) {
        let itemTotal = item.total;
        //calc taxes
        taxes.forEach((tax) => {
          if (tax?.value_type === "value") {
            itemTotal += tax?.value;
          } else {
            const persentage = itemTotal * (tax?.value / 100);
            itemTotal += persentage;
          }
        });
        //inc the total
        totalAfterTaxes += itemTotal;
      } else {
        totalAfterTaxes += item.total;
      }
    });
    // taxes.forEach((tax) => {
    //   if (tax?.value_type === "value") {
    //     totalAfterTaxes += tax?.value;
    //   } else {
    //     const persentage = amount * (tax?.value / 100);
    //     totalAfterTaxes += amount + persentage;
    //   }
    // });

    return totalAfterTaxes;
  };
  return (
    <div>
      <div className="basic-info">
        <Row gutter={[10, 0]}>
          {/* ------------------------------------------------------------------ */}
          <div className={InvoiceCopyStyles}>
            <div className="float">copy</div>
            <div className="title">
              <Col span={24}>
                <Row>
                  <Col span={16} className="title_img">
                    {!!currentUser?.logo && !logoImgError ? (
                      <img
                        onError={() => setLogoImgError(true)}
                        src={currentUser?.logo}
                        alt="POS"
                      />
                    ) : (
                      <img src={posPlaceholder} alt="POS" />
                    )}
                  </Col>
                  <Col span={8} className="title_header">
                    {/* <span> */}
                    صندوق تحسين خدمات الرعاية الإجتماعية والصحية لأعضاء هيئة
                    الشرطة وأسرهم
                    {/* </span> */}
                  </Col>
                </Row>
              </Col>

              <div>Pos Serials number: {orderDetail?.pos_serial || "-"}</div>
            </div>
            <div className="basic-info">
              <Row gutter={[10, 0]} align="end">
                {/* <Col span={isWaiterNameAvailable ? 12 : 24}>
                  <Flex justify="start">
                    outlet: {orderDetail?.point_of_sale}
                  </Flex>
                </Col> */}

                {/* {isWaiterNameAvailable && (
                  <Col span={12}>
                    <Flex justify="end">staff name: {orderDetail?.staff}</Flex>
                  </Col>
                )} */}
                <Col span={24}>
                  <Row gutter={20} justify="space-between">
                    <Col>
                      <div>table: {orderDetail?.table_number}</div>
                    </Col>
                    <Col>
                      <div>date: {orderDetail?.created_at}</div>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={20} justify="space-between">
                    <Col>
                      <div className="alin-right">
                        taken by: {orderDetail?.organization_admin}
                      </div>
                    </Col>
                    <Col>
                      <div>shift: {orderDetail?.shift}</div>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={20} justify="space-between">
                    <Col>
                      <div>order type: {orderDetail?.type}</div>
                    </Col>
                    <Col>
                      <div>serial: #{orderDetail?.id}</div>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={20} justify="space-between">
                    <Col>
                      <div>
                        Serials number: {orderDetail?.multi_serials || "-"}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="order-info">
              <Row gutter={[10, 20]}>
                <Col span={24}>
                  <div>opening time: {orderDetail?.opening_time}</div>
                </Col>
                <Table
                  className="ant-table-container"
                  dataSource={orderDetail?.order_items}
                  columns={cols}
                  pagination={false}
                  bordered={true}
                  size="small"
                  summary={() => (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={3}>
                          الإجمالي
                        </Table.Summary.Cell>
                        <Table.Summary.Cell colSpan={2}>
                          {currencyFormat(orderDetail?.subTotal)}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>

                      {orderDetail?.total_amount != 0 &&
                        ((orderDetail?.status == "closed" &&
                          orderDetail?.taxes.length >= 1) ||
                          orderDetail?.status == "pending") &&
                        taxesArray &&
                        taxesArray.map((tax) => {
                          return (
                            <>
                              <Table.Summary.Row className="small-margin-row">
                                <Table.Summary.Cell
                                  colSpan={3}
                                  style={{ padding: "5px" }}
                                >
                                  {tax.tax_name}
                                  {` ${
                                    tax.value_type === "percentage"
                                      ? "%" + tax.value
                                      : ""
                                  }`}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={2}>
                                  {/* {currencyFormat(
                                    calcTotalAfterDiscount(
                                      orderDetail?.subTotal,
                                      orderDetail?.discount,
                                      orderDetail?.discount_type
                                    ).tax
                                  )} */}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          );
                        })}
                      {orderDetail?.total_amount != 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={3}>
                            الإجمالي بعد الضريبة
                          </Table.Summary.Cell>
                          <Table.Summary.Cell colSpan={2}>
                            {currencyFormat(
                              calcTotalAfterTaxes(taxesArray, orderDetail)
                            )}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      )}
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={4}>
                          تم إلغاء الأوردر
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  )}
                />

                {orderDetail?.status == "closed" && (
                  <Col span={24}>closing time: {orderDetail?.closing_time}</Col>
                )}
              </Row>
            </div>
            {getTaxReg() && getTaxCode() && (
              <Col span={24}>
                <Row gutter={[10, 8]} justify="center">
                  <Col>
                    <Flex justify="end">
                      <span style={{ direction: "ltr", marginRight: "5px" }}>
                        {getTaxReg() || ""}
                      </span>
                      :رقم التسجيل الضريبي
                    </Flex>
                  </Col>
                </Row>
                <Row gutter={[10, 8]} justify="center">
                  <Col md={6} sm={12}>
                    <Flex justify="start">كود الفرع: {getTaxCode() || ""}</Flex>
                  </Col>
                </Row>
              </Col>
            )}
          </div>
        </Row>
      </div>
    </div>
  );
}

export default CanceledOrderInvoice;
