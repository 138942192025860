export const queryKeys = {
  user: 'user',
  profile: 'profile',
  addressList: 'address-list',
  paymentList: 'payment-list',
  notificationList: 'notification-list',
  emergencyList: 'emergency-list',
  marketingInfoList: 'marketing-info-list',
  nutritionInfoList: 'nutrition-info-list',
  areaList: 'area-list',
  cartList: 'cart-list',

  mealplanList: 'mealplan-list',
  mealplanItem: 'mealplan-item',
  menuList: 'menu-list',
  mealsList: 'meals-list',
  calendar: 'calendar',

  sessionList: 'session-list',
  sessionUserList: 'session-user-list',
  sessionInvocie: 'session-Invocie',

  packageList: 'package-list',
  packageUserList: 'package-user-list',
  packageInvoice: 'package-invoice',
  packageOrderSummary: 'package-order-summary',

  subscriptionList: 'subscription-list',
  subscriptionInvocie: 'subscription-Invocie',
  subscriptionOrderSummary: 'subscription-order-summary',

  userSubscriptionCurrent: 'user-subscription-current',
  userSubscriptionHistory: 'user-subscription-history',
  userSubscriptionDetails: 'user-subscription-details',

  userDeliveryUpcoming: 'user-delivery-upcoming',
  userOverview: 'user-overview',
  homeData: 'home-data',

  getClientsForHotels: 'getClientsForHotels',
  getDataLowest: 'getDataLowest',
  getRoomsForHotels: 'getRoomsForHotels',
};
