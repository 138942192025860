import { Modal, Spin, Table, Row, Button } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useGetDataLowest } from "../../../../api-hooks/useDataLowest";
import "./DataLowestModal.scss";
import { currencyFormat } from "../../../../services/utils";
import { useReactToPrint } from "react-to-print";

const DataLowestModal = ({ open, setOpen }) => {
  const [currnetPage, setCurrentPage] = useState(1);
  const { data, refetch, isFetching } = useGetDataLowest({
    page: currnetPage,
    open,
  });

  const printRef = useRef();

  const dataFormated = useMemo(() => {
    const items = data?.data?.filter((item) => item?.total > 0);
    return items;
  }, [data]);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [refetch, open]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Data Lowest",
  });

  const productColumns = [
    {
      title: "اسم المنتج", // Product name
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "الكمية", // Quantity
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
    },
    {
      title: "الإجمالي", // Total
      dataIndex: "amount",
      key: "amount",
      align: "center",
    },
  ];

  const columns = [
    {
      title: "فئة", // Category
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (name) => <b>{name}</b>,
    },
    {
      title: "منتجات", // Products
      key: "products",
      align: "center",
      render: (_, record) => (
        <div style={{ padding: "10px" }}>
          <Table
            dataSource={record.items}
            columns={productColumns}
            pagination={false}
            size="small"
            rowKey="key"
            bordered
            style={{
              tableLayout: "fixed",
              whiteSpace: "nowrap",
            }}
            summary={() => (
              <>
                {record && (
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={2}>
                      الإجمالي
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {record ? currencyFormat(record?.total) : 0}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
              </>
            )}
          />
        </div>
      ),
    },
  ];

  return (
    <Modal
      visible={open}
      footer={null}
      onCancel={() => setOpen(false)}
      destroyOnClose
      width={800}
    >
      <Spin spinning={isFetching}>
        {/* Parent table with categories and nested product tables */}
        <div
          ref={printRef}
          style={{
            marginBlock: "10px",
            marginInline: "10px",
          }}
        >
          <Table
            dataSource={dataFormated}
            columns={columns}
            pagination={false}
            rowKey="key"
            bordered
            style={{
              tableLayout: "fixed",
              whiteSpace: "nowrap",
            }}
          />
        </div>
      </Spin>
      <Row
        justify="center"
        gutter={[10, 8]}
        style={{
          marginTop: "5px",
        }}
      >
        <Button type="primary" size="large" onClick={handlePrint}>
          اطبع
        </Button>
      </Row>
    </Modal>
  );
};

export default DataLowestModal;
