import { css } from "@emotion/css";
import { Col, Row, Table } from "antd";
import { useSelector } from "react-redux";
import { useCurrentLoginType } from "../../hooks/useCurrentLoginType";
import { currencyFormat } from "../../services/utils";
import Flex from "../Flex/Flex";
import posPlaceholder from "../../assets/pos-order-logo.jpeg";
import { useEffect, useMemo, useState } from "react";
import "./incoiveStyle.css";
import { getTaxCode, getTaxes, getTaxReg } from "../../helper/localStorage";
import { usePrintItem } from "../../hooks/query/usePrint";
import PreparationAreaItems from "./PreparationAreaItems";
// import logo from '../../assets/pos-logo.jpeg';

function InvoiceCopy({
  invoice,
  paymentReccived,
  isGroupOrderItem = false,
  ...rest
}) {
  console.log({ invoice });
  const InvoiceCopyStyles = css`
    width: 30rem;
    max-width: 100%;
    margin: auto;
    position: relative;
    padding: 3rem 1.5rem 2rem;
    /* border: 1px solid #777;› */
    background-color: #fff;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    font-size: 12px;
    text-transform: capitalize;
    line-height: 1.4;
    color: rgba(0, 0, 0, 0.95);

    .float {
      position: absolute;
      top: 2rem;
      right: 2rem;
      color: #444;
    }

    .alin-right {
      text-align: end;
    }
    .title {
      font-weight: 600;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3px;
      margin-bottom: 0.5rem;

      img {
        max-width: 7rem;
      }
    }
    .basic-info {
      padding-bottom: 1rem;
      border-bottom: 1px solid #aaa;
    }
  `;

  const cols = [
    {
      title: "الكمية",
      dataIndex: "quantity",
      key: "qty",
      align: "center",
    },
    {
      title: "المنتج",
      dataIndex: "productName",
      key: "name",
      align: "center",
    },
    {
      title: "السعر",
      dataIndex: "price",
      key: "price",
      align: "center",
    },
    {
      title: "الاجمالي",
      dataIndex: "total",
      key: "total",
      align: "center",
      colSpan: 2,
      // onHeaderCell: () => ({
      //   colSpan: 0,
      // }),
      render: (_, record) => {
        return {
          children: <span>{record.total}</span>,
          props: {
            colSpan: 2,
          },
        };
      },
    },
  ];

  const PayColumns = [
    {
      title: "طريقة الدفع",
      dataIndex: "type",
      key: "method",
      align: "center",
    },
    {
      title: "المبلغ",
      dataIndex: "amount",
      key: "amount",
      align: "center",
    },
  ];

  const currentUser = useSelector((s) => s?.auth?.currentUser);
  const [logoImgError, setLogoImgError] = useState(false);
  const orderItems = useMemo(() => {
    console.log({ invoice });
    const items = invoice?.order_items;
    if (!isGroupOrderItem || items?.length <= 1 || !items) return items;

    const foundItems = {};

    for (let i of items) {
      const itemId = i?.itemId;
      const quantity = parseFloat(i?.quantity) || 0;
      const total = parseFloat(i?.total) || 0;

      if (itemId) {
        if (foundItems[itemId]) {
          foundItems[itemId].quantity += quantity;
          foundItems[itemId].total += total;
        } else {
          foundItems[itemId] = { ...i, quantity, total }; // Create a copy of the item
        }
      }
    }
    return Object.values(foundItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice?.order_items, isGroupOrderItem]);

  console.log({ orderItems });
  const isWaiterNameAvailable = invoice?.staff && invoice?.staff !== "-";

  const taxesArray = getTaxes();

  const calcTotalAfterTaxes = (taxes, order) => {
    // const taxes = getTaxes();
    console.log({ taxes, order });

    let totalAfterTaxes = 0;
    order?.order_items.forEach((item) => {
      if (item.taxable == 1) {
        let itemTotal = item.total;
        //calc taxes
        taxes.forEach((tax) => {
          if (tax?.value_type === "value") {
            itemTotal += tax?.value;
          } else {
            const persentage = itemTotal * (tax?.value / 100);
            itemTotal += persentage;
          }
        });
        //inc the total
        totalAfterTaxes += itemTotal;
      } else {
        totalAfterTaxes += item.total;
      }
    });
    // taxes.forEach((tax) => {
    //   if (tax?.value_type === "value") {
    //     totalAfterTaxes += tax?.value;
    //   } else {
    //     const persentage = amount * (tax?.value / 100);
    //     totalAfterTaxes += amount + persentage;
    //   }
    // });

    return totalAfterTaxes;
  };

  const sumbitItemToPrint = usePrintItem();

  const calcTotalAfterDiscount = (total, discount, discountType) => {
    const taxes = getTaxes();

    let totalTaxes = 0;
    let individualTaxes = 0;
    let finalTotal = 0;

    invoice?.order_items.forEach((item) => {
      //total price of the item
      let itemTotal = item.total;
      console.log(discountType);

      // Apply the discount based on its type
      if (discountType === "value") {
        itemTotal -= itemTotal * (discount / total); // Split the discount between items
      } else if (discountType === "percentage") {
        console.log({
          itemTotal,
        });
        itemTotal -= itemTotal * (discount / 100); // Percentage discount
        console.log({
          itemTotal,
          discount,
          total,
        });
      }

      // After discount, if the item is taxable, calculate the taxes
      if (item.taxable == 1) {
        taxes.forEach((tax) => {
          individualTaxes = itemTotal * (tax?.value / 100); // Apply tax on the discounted price
          totalTaxes += individualTaxes;
          itemTotal += individualTaxes;
        });
      }

      // Add the item's total (with tax and discount) to the final total
      finalTotal += itemTotal;
    });

    return {
      total: finalTotal.toFixed(2),
      tax: totalTaxes,
    }; // Return the total with two decimal places
  };
  return (
    <div className={InvoiceCopyStyles} {...rest}>
      <div className="float">copy</div>
      <div className="title">
        <Col span={24}>
          <Row>
            <Col span={16} className="title_img">
              {!!currentUser?.logo && !logoImgError ? (
                <img
                  onError={() => setLogoImgError(true)}
                  src={currentUser?.logo}
                  alt="POS"
                />
              ) : (
                <img src={posPlaceholder} alt="POS" />
              )}
            </Col>
            <Col span={8} className="title_header">
              {/* <span> */}
              صندوق تحسين خدمات الرعاية الإجتماعية والصحية لأعضاء هيئة الشرطة
              وأسرهم
              {/* </span> */}
            </Col>
          </Row>
        </Col>

        <div>Pos Serials number: {invoice?.pos_serial || "-"}</div>
      </div>
      <div className="basic-info">
        <Row gutter={[10, 0]} align="end">
          <Col span={isWaiterNameAvailable ? 12 : 24}>
            <Flex justify="start">outlet: {invoice?.point_of_sale}</Flex>
          </Col>

          {isWaiterNameAvailable && (
            <Col span={12}>
              <Flex justify="end">staff name: {invoice?.staff}</Flex>
            </Col>
          )}
          <Col span={24}>
            <Row gutter={20} justify="space-between">
              <Col>
                <div>table: {invoice?.table_number}</div>
              </Col>
              <Col>
                <div>date: {invoice?.created_at}</div>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={20} justify="space-between">
              {/* <Col>
                <div>guests: 1</div>
              </Col> */}
              <Col>
                <div className="alin-right">
                  taken by: {invoice?.organization_admin}
                </div>
              </Col>
              <Col>
                <div>shift: {invoice?.shift}</div>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={20} justify="space-between">
              <Col>
                <div>order type: {invoice?.type}</div>
              </Col>
              <Col>
                <div>serial: #{invoice?.id}</div>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={20} justify="space-between">
              <Col>
                <div>Serials number: {invoice?.multi_serials || "-"}</div>
              </Col>
            </Row>
          </Col>
          {/* <Col span={24}>
            <div className="alin-right">
              taken by: {invoice?.organization_admin}
            </div>
          </Col> */}
        </Row>
      </div>
      <div className="order-info">
        <Row gutter={[10, 20]}>
          <Col span={24}>
            <div>opening time: {invoice?.opening_time}</div>
          </Col>
          <Table
            className="ant-table-container"
            dataSource={orderItems}
            columns={cols}
            pagination={false}
            bordered={true}
            size="small"
            summary={() => (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={3}>الإجمالي</Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={2}>
                    {currencyFormat(invoice?.subTotal)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>

                {invoice?.discount && (
                  <>
                    <Table.Summary.Row className="small-margin-row">
                      <Table.Summary.Cell colSpan={3}>الخصم</Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={1}>
                        {invoice?.discount_type === "percentage"
                          ? invoice?.discount + "%"
                          : currencyFormat(invoice?.discount)}
                        {/* <div
                          style={{
                            display: "flex",
                            alingItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <span
                          // style={{
                          //   paddingLeft: "5px",
                          // }}
                          >
                            {invoice?.discount_type === "percentage"
                              ? invoice?.discount + "%"
                              : currencyFormat(invoice?.discount)}
                          </span>
                          {invoice?.discount_type === "percentage" && (
                            <span
                              style={{
                                width: "1px",
                                height: "20px",
                                backgroundColor: "black",
                              }}
                            ></span>
                          )}
                          {invoice?.discount_type === "percentage" && (
                            <span>
                              {currencyFormat(
                                invoice?.subTotal * (invoice?.discount / 100)
                              )}
                            </span>
                          )}
                        </div> */}
                      </Table.Summary.Cell>
                      {invoice?.discount_type === "percentage" && (
                        <Table.Summary.Cell colSpan={1}>
                          {currencyFormat(
                            invoice?.subTotal * (invoice?.discount / 100)
                          )}
                        </Table.Summary.Cell>
                      )}
                    </Table.Summary.Row>
                    {invoice?.total_amount != 0 && (
                      <Table.Summary.Row className="small-margin-row">
                        <Table.Summary.Cell colSpan={3}>
                          الصافي
                        </Table.Summary.Cell>
                        <Table.Summary.Cell colSpan={2}>
                          {currencyFormat(invoice?.total_amount)}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    )}
                  </>
                )}
                {invoice?.total_amount == 0 && (
                  <Table.Summary.Row className="small-margin-row">
                    <Table.Summary.Cell colSpan={5}>ضيافة</Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
                {/* {invoice?.taxes &&
                  invoice?.taxes?.map((tax) => {
                    return (
                      <>
                        <Table.Summary.Row className="small-margin-row">
                          <Table.Summary.Cell
                            colSpan={3}
                            style={{ padding: "5px" }}
                          >
                            {tax.tax}
                            {` ${
                              tax.tax_value.includes("%") ? tax.tax_value : ""
                            }`}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell colSpan={2}>
                            {tax.value}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  })} */}
                {invoice?.total_amount != 0 &&
                  ((invoice?.status == "closed" &&
                    invoice?.taxes.length >= 1) ||
                    invoice?.status == "pending") &&
                  taxesArray &&
                  taxesArray.map((tax) => {
                    return (
                      <>
                        <Table.Summary.Row className="small-margin-row">
                          <Table.Summary.Cell
                            colSpan={3}
                            style={{ padding: "5px" }}
                          >
                            {tax.tax_name}
                            {` ${
                              tax.value_type === "percentage"
                                ? "%" + tax.value
                                : ""
                            }`}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell colSpan={2}>
                            {currencyFormat(
                              calcTotalAfterDiscount(
                                invoice?.subTotal,
                                invoice?.discount,
                                invoice?.discount_type
                              ).tax
                            )}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  })}
                {invoice?.total_amount != 0 &&
                  ((invoice?.status == "closed" &&
                    invoice?.taxes.length >= 1) ||
                    invoice?.status == "pending") && (
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={3}>
                        الإجمالي بعد الضريبة
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={2}>
                        {invoice?.discount
                          ? currencyFormat(
                              calcTotalAfterDiscount(
                                invoice?.subTotal,
                                invoice?.discount,
                                invoice?.discount_type
                              ).total
                            )
                          : currencyFormat(
                              calcTotalAfterTaxes(taxesArray, invoice)
                            )}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
              </>
            )}
          />

          {/* <Col span={24}>
            <div className="order-items-wrapper">
              <Row gutter={[10, 0]}>
                <Col span={24}>
                  <Row gutter={10} justify="space-between">
                    <Col
                      span={4}
                      style={{ fontSize: 14, border: "1px solid black" }}
                    >
                      الكمية
                    </Col>
                    <Col
                      span={8}
                      style={{ fontSize: 14, border: "1px solid black" }}
                    >
                      المنتج
                    </Col>
                    <Col
                      span={6}
                      style={{
                        fontSize: 14,
                        textAlign: "center",
                        border: "1px solid black",
                      }}
                    >
                      السعر
                    </Col>
                    <Col
                      span={6}
                      style={{
                        fontSize: 14,
                        textAlign: "end",
                        border: "1px solid black",
                      }}
                    >
                      الاجمالي
                    </Col>
                  </Row>
                </Col>
                {orderItems?.map((el) => (
                  <Col span={24} key={el?.id}>
                    <Row gutter={10} justify="space-between">
                      <Col
                        span={4}
                        style={{ fontSize: 14, border: "1px solid black" }}
                      >
                        {`${el?.quantity}`}
                      </Col>
                      <Col
                        span={8}
                        style={{ fontSize: 14, border: "1px solid black" }}
                      >
                        {`${el?.productName}`}
                      </Col>

                      <Col
                        span={6}
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          border: "1px solid black",
                        }}
                      >
                        {currencyFormat(el?.price)}
                      </Col>
                      <Col
                        span={6}
                        style={{
                          textAlign: "end",
                          fontSize: 14,
                          fontWeight: 600,
                          border: "1px solid black",
                        }}
                      >
                        {currencyFormat(el?.quantity * el?.price)}
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </div>
          </Col> */}
          <Col span={24}>
            <Row gutter={[10, 0]}>
              <Col span={24}>
                {/* {!!invoice?.discount && (
                  <Row justify="space-between">
                    <Col>
                      <div>
                        {invoice?.discount_type === "percentage" &&
                        +invoice?.discount === 100
                          ? "ضيافة"
                          : "Discount"}
                      </div>
                    </Col>
                    <Col>
                      <div style={{ fontWeight: 600 }}>
                        {invoice?.discount_type === "percentage"
                          ? invoice?.discount + "%"
                          : currencyFormat(invoice?.discount)}
                      </div>
                    </Col>
                  </Row>
                )} */}
                {/* <Row justify="space-between">
                  <Col>
                    <div style={{ fontWeight: "700", fontSize: "16px" }}>
                      {invoice?.discount_type === "percentage" &&
                      +invoice?.discount === 100
                        ? "ضيافة"
                        : "total"}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ fontWeight: 700 }}>
                      {currencyFormat(invoice?.total_amount)}
                    </div>
                  </Col>
                </Row> */}
              </Col>
              {invoice?.status != "pending" && invoice?.total_amount != 0 && (
                <Col span={24}>
                  {/* <Row justify="space-between" style={{ marginTop: 10 }}>
                    <Col>
                      <div style={{ fontWeight: 700, fontSize: "16px" }}>
                        Payment:
                      </div>
                    </Col>
                    <Col>
                      <div style={{ fontWeight: 700, fontSize: "16px" }}>
                      {invoice?.order_payment === "credit"
                        ? "مؤجل"
                        : invoice?.order_payment.map((el) => (
                            <span key={el.type}>{el.type}</span>
                          ))}
                    </div>
                    </Col>
                  </Row> */}
                  <Table
                    className="ant-table-container"
                    dataSource={
                      Array.isArray(invoice?.order_payment)
                        ? invoice?.order_payment
                        : [invoice?.order_payment]
                    }
                    columns={PayColumns}
                    pagination={false}
                    bordered={true}
                    size="small"
                  />
                </Col>
              )}
            </Row>
          </Col>

          {invoice?.status == "closed" && (
            <Col span={24}>closing time: {invoice?.closing_time}</Col>
          )}
          {
            <Col span={24}>
              <div style={{ margin: "0 auto", textAlign: "center" }}>
                {invoice?.status == "closed" ? (
                  <>
                    <p>THANK YOU FOR YOUR PURCHASE</p>
                    <p>HAVE A NICE DAY 😉</p>
                  </>
                ) : (
                  "Payment not recevied"
                )}
              </div>
            </Col>
          }
          {/* <Col span={24}>
            <div>payment recived, thank you.</div>
          </Col> */}
        </Row>
      </div>
      {getTaxReg() && getTaxCode() && (
        <Col span={24}>
          <Row gutter={[10, 8]} justify="center">
            <Col>
              <Flex justify="end">
                <span style={{ direction: "ltr", marginRight: "5px" }}>
                  {getTaxReg() || ""}
                </span>
                :رقم التسجيل الضريبي
              </Flex>
            </Col>
          </Row>
          <Row gutter={[10, 8]} justify="center">
            <Col md={6} sm={12}>
              <Flex justify="start">كود الفرع: {getTaxCode() || ""}</Flex>
            </Col>
          </Row>
        </Col>
      )}
      {/* Split point for the two pages */}
      {invoice?.type == "توصيل" && invoice?.order_items[0]?.is_print == "0" && (
        <PreparationAreaItems
          items={invoice?.order_items}
          sumbitItemToPrint={sumbitItemToPrint}
          invoice={invoice}
        />
      )}
      {/* <div style={{ marginTop: 0 }}>closing time: {invoice?.closing_time}</div> */}
    </div>
  );
}

export default InvoiceCopy;
