import React from "react";
import classes from "./CartCounter.module.scss";
import { locale } from "../../../../locale";
import { useCurrentLang } from "../../../../hooks/useCurrentLang";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { currencyFormat } from "../../../../services/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  removeComments,
  setCartToShowSavedOrder,
  setCurrentSavedOrderIdAction,
} from "../../../../store/cartSlice";
import { axiosInstance } from "../../../../service/api";

const CartCounter = ({ prices, count }) => {
  const { showSavedOrder, cartComments } = useSelector((state) => state.cart);
  const posId = useSelector((state) => state.auth.posId);
  const dispatch = useDispatch();

  const [currentLang] = useCurrentLang();
  const cartLocale = locale.sidebar.cart;
  const navigate = useNavigate();
  const onClickHandler = async () => {
    const body = new FormData();
    body.append("point_of_sale_id", posId);
    body.append("comment", JSON.stringify(cartComments));
    axiosInstance().post("/addCartComments", body);
    dispatch(removeComments());
    if (showSavedOrder) {
      dispatch(setCartToShowSavedOrder(false));
      dispatch(setCurrentSavedOrderIdAction(null));
      navigate("/on-hold");
      return;
    }
    if (!prices || !count) {
      message.error(cartLocale.validation.emptyCart[currentLang]);
      return;
    }
    navigate("/checkout");
  };
  return (
    <Button onClick={onClickHandler} className={classes.CartCounter}>
      <p>{cartLocale.title[currentLang]}</p>
    </Button>
  );
};

export default CartCounter;
