import { Input } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addComment } from "../../../store/cartSlice";

const CartSingleItem = ({ item }) => {
  const [itemComment, setItemComment] = useState("");
  const dispatch = useDispatch();

  const handleSubmittion = () => {
    dispatch(
      addComment({
        cart_id: item?.id,
        comment: itemComment,
      })
    );
    // addComment(item?.id, itemComment);
  };
  return (
    <Input
      placeholder="ضع ملاحظاتك"
      dir="rtl"
      onChange={(e) => setItemComment(e.target.value)}
      onBlur={handleSubmittion}
    />
  );
};

export default CartSingleItem;
