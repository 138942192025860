import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSavedOrder: false,
  currentSavedOrderId: null,
  cart: null,
  currentSavedOrderTableNumber: null,
  cartComments: [],
};

const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {
    setCurrentSavedOrderIdAction: (state, { payload }) => {
      state.currentSavedOrderId = payload;
    },
    setCurrentSavedOrderTableNumber: (state, { payload }) => {
      state.currentSavedOrderTableNumber = payload;
    },
    setCartToShowSavedOrder: (state, { payload }) => {
      state.showSavedOrder = payload;
    },
    setCart: (state, { payload }) => {
      state.cart = payload;
    },
    addComment: (state, action) => {
      console.log("from store");
      console.log(action.payload);
      const comment = action.payload;
      if (!Array.isArray(state.cartComments)) {
        state.cartComments = []; // Initialize if not an array
      }

      state.cartComments.push(comment); // Add the comment to the array
    },
    removeComments: (state) => {
      state.cartComments = [];
    },
  },
});

export const {
  setCartToShowSavedOrder,
  setCurrentSavedOrderIdAction,
  setCurrentSavedOrderTableNumber,
  setCart,
  addComment,
  removeComments,
} = cartSlice.actions;
export default cartSlice.reducer;
