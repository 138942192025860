export const queryKeys = {
  getAllData: "getAllData",
  getWaiterActiveShifts: "getWaiterActiveShifts",
  userData: "userData",
  getProducts: "getProducts",
  getCategories: "getCategorise",
  getPointsOfSales: "getPointsOfSales",
  getCart: "getCart",
  getOrders: "getOrders",
  getSavedOrder: "getSavedOrder",
  //
  cartInfo: "cart-info",
  placesList: "places-list",
  sheetReport: "sheet-report",
  orderDetails: "order-details",
};
