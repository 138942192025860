export const getToken = () => {
  const token = localStorage.getItem("token");
  if (!token) return null;

  return JSON.parse(token);
};

export const setToken = (token) => {
  localStorage.setItem("token", token);
};

export const removeToken = () => {
  localStorage.removeItem("token");
};

export const setPointOfSale = (pos) => {
  localStorage.setItem("pos", pos);
};

export const getPointOfSale = () => {
  const pos = localStorage.getItem("pos");
  if (!pos) return null;
  return JSON.parse(pos);
};

export const removePointOfSale = () => {
  localStorage.removeItem("pos");
};

export const setShiftId = (id) => {
  localStorage.setItem("shifId", id);
};

export const setTaxes = (taxes) => {
  localStorage.setItem("taxes", JSON.stringify(taxes));
};

export const getTaxes = () => {
  const taxes = localStorage.getItem("taxes");
  if (!taxes) return null;
  return JSON.parse(taxes);
};

export const setTaxReg = (taxReg) => {
  let str;
  if (taxReg) {
    str = JSON.stringify(taxReg);
  } else {
    str = null;
  }
  localStorage.setItem("taxReg", str);
};

export const getTaxReg = () => {
  const taxReg = localStorage.getItem("taxReg");
  if (!taxReg) return null;
  return JSON.parse(taxReg);
};

export const setTaxCode = (taxCode) => {
  let str;
  if (taxCode) {
    str = JSON.stringify(taxCode);
  } else {
    str = null;
  }
  localStorage.setItem("taxCode", str);
};

export const getTaxCode = () => {
  const taxCode = localStorage.getItem("taxCode");
  if (!taxCode) return null;
  return JSON.parse(taxCode);
};

export const getShiftId = () => {
  const shifId = localStorage.getItem("shifId");
  if (!shifId) return null;
  return JSON.parse(shifId);
};

export const removeShifId = () => {
  return localStorage.removeItem("shifId");
};
export const clearDataStorage = () => {
  removeToken();
  removePointOfSale();
  removeShifId();
};
