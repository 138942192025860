import React, { useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import classes from "./ReceiptDetails/ReceiptDetails.module.scss";
import { Button, Spin } from "antd";
import LeftArrow from "../assets/arrow-left.png";
import useOrderDetails from "../api-hooks/useOrderDetails";
import CanceledOrderInvoice from "./CanceledOrderInvoice";
function CanceledRecipt() {
  const { id } = useParams();
  const { orderDetail, orderDetailLod } = useOrderDetails(id);

  const orderRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => orderRef.current,
  });
  return (
    <div className={classes.ReceiptDetails}>
      <Link className={classes.ReceiptDetails__Link} to="/canceled-order">
        <img src={LeftArrow} alt="left arrow" />
        العودة للطلبات الملغاة
      </Link>
      <Spin spinning={orderDetailLod}>
        <div
          style={{
            padding: 20,
            marginTop: 20,
            minHeight: "85vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 25,
            background: "#fff",
          }}
        >
          <div
            style={{
              border: "1px solid #bbb",
              borderRadius: 4,
            }}
          >
            <div ref={orderRef}>
              <CanceledOrderInvoice orderDetail={orderDetail} />
            </div>
          </div>
          <Button
            type="primary"
            size="large"
            style={{ minWidth: 250 }}
            onClick={() => {
              handlePrint();
            }}
          >
            اطبع
          </Button>
        </div>
      </Spin>
    </div>
  );
}

export default CanceledRecipt;
