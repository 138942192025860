import authPage from './authPage.json';
import productPage from './productPage.json';
import validation from './Validation.json';
import sidebar from './sidebar.json';
import global from './global.json';
import searchbar from './searchbar.json';
import categoires from './categories.json';
import checkout from './checkout.json';
import reviewOrder from './reviewOrder.json';
import orderPlaced from './orderPlaced.json';
import cashMangment from './cashMangment.json';
import savedOrders from './savedOrders.json';
import orders from './orders.json';

export const locale = {
  global,
  productPage,
  validation,
  sidebar,
  authPage,
  searchbar,
  categoires,
  checkout,
  reviewOrder,
  orderPlaced,
  cashMangment,
  savedOrders,
  orders,
};
