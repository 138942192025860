import React from "react";
import classes from "./CartSidebar.module.scss";
import CartHeader from "./CartHeader/CartHeader";
import CartCounter from "./CartCounter/CartCounter";
import { Divider } from "antd";
import Text from "../../Text/Text";
import Flex from "../../Flex/Flex";
import { useCurrentLang } from "../../../hooks/useCurrentLang";
import { locale } from "../../../locale";
import CartItems from "./CartItems/CartItems";
import { useCurrentCartItems } from "../../../hooks/useCurrentCartItems";
import { currencyFormat } from "../../../services/utils";
import { useDispatch } from "react-redux";
import { addComment } from "../../../store/cartSlice";

const CartSidebar = () => {
  const [currentLang] = useCurrentLang();
  const orderlabels = locale.sidebar.cart.orderLables;

  const { data: currentCartItem, isLoading: cartIsLoading } =
    useCurrentCartItems();
  // console.log('CartSidebar  currentCartItem>>>>>', currentCartItem);
  // const { cartInfo: currentCartItem, cartInfoLod: cartIsLoading } =
  //   useCartInfo();
  // console.log('CartSidebar  cartInfo', currentCartItem);

  //adding a comment to the cart items
  const dispatch = useDispatch();
  // const [comments, setComments] = useState([]);

  // Function to update comments
  const handleCommentChange = (itemId, comment) => {
    // setComments((prev) => [...prev, { cart_id: itemId, comment: comment }]);
    dispatch(addComment({ cart_id: itemId, comment: comment }));
  };

  return (
    <div className={classes.CartSidebar}>
      <CartHeader />
      <CartItems addComment={handleCommentChange} />
      <Flex
        style={{
          padding: "18px",
        }}
        gap="17px"
        direction="column"
      >
        <Divider style={{ margin: "0" }} />
        <Flex justify="space-between">
          <Text color="primary">
            جم {currencyFormat(currentCartItem?.total ?? 0)}
          </Text>
          <Text label>{orderlabels.total[currentLang]}</Text>
        </Flex>
      </Flex>
      <CartCounter
        count={currentCartItem?.items?.length}
        prices={currentCartItem?.total}
      />
    </div>
  );
};

export default CartSidebar;
